import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { blue, orange, gray } from "../../styles";
import ReactMarkdown from "react-markdown";

const AwardHeader = styled.h2`
  border-bottom: 5px solid ${blue};
`;
const AwardName = styled.span`
  text-transform: capitalize;
  color: ${orange};
  font-weight: bold;
  font-size: 26px;
`;

const AwardWrapper = styled.div`
  margin: 0 0 60px;
  @media print {
    margin: 0;
  }
`;

const AwardeeWrapper = styled.div`
  color: black;
  margin-top: 20px;
  margin-bottom: 20px;
  @media print {
    page-break-after: always;
    font-size: 10px;
  }
`;
const CandidateCheck = styled.div`
  color: black;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.6;
  margin-bottom: 0;
  @media print {
    font-size: 10px;
  }
`;
const CandidateName = styled.p`
  font-weight: bolder;
  color: ${blue};
  margin: 0;
`;
const CandidateTitles = styled.p`
  color: ${gray};
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
  @media print {
    font-size: 10px;
  }
`;
const ProfileHr = styled.hr`
  margin: 12px 0 6px;
`;
const Photo = styled.img`
  border-radius: 12px;
  max-width: 65%;
`;
const InfoLabel = styled.span`
  font-weight: bold;
  color: ${blue};
`;
const WriteInLabel = styled.label`
  width: 100%;
`;
const LeftSection = styled.div`
  padding: 0;
  @media print {
    width: 25%;
  }
`;
const RightSection = styled.div`
  padding-left: 0;
  @media print {
    width: 75%;
  }
`;

const Awardee = ({ awardee }) => {
  const researchComplete =
    awardee.reasearch_description !== "" &&
    awardee.reasearch_description !== "INCOMPLETE";
  const reactionComplete =
    awardee.reaction !== "" && awardee.reaction !== "INCOMPLETE";
  return (
    <React.Fragment>
      <AwardeeWrapper className="row">
        <LeftSection className="col-xs-12 col-md-6 col-lg-3">
          <div className="row">
            <div
              className="col-xs-12"
              style={{ textAlign: "center", padding: 0 }}
            >
              <Photo
                src={`/images/headshots/2024/${awardee.applicantId}_${awardee.last_name}.jpg`}
                alt={`Photograph of ${awardee.first_name} ${awardee.last_name}`}
                className="img-fluid"
              />
            </div>
          </div>
        </LeftSection>

        <RightSection className="col-lg-9 col-md-6 col-xs-12">
          <p>
            <InfoLabel>Recipient:</InfoLabel> {awardee.first_name}{" "}
            {awardee.last_name}
            <br />
            <InfoLabel>Highest Degree:</InfoLabel> <br />
            <InfoLabel>Institution/Affiliation:</InfoLabel>
            <br />
          </p>
          {researchComplete && <p>{awardee.reasearch_description}</p>}

          {reactionComplete && (
            <ReactMarkdown>{awardee.reaction}</ReactMarkdown>
          )}
        </RightSection>
      </AwardeeWrapper>
    </React.Fragment>
  );
};

const AwardeeMemo = React.memo(Awardee);
function AwardCard({ awardName = "", awardeeList = [] }) {
  return (
    <React.Fragment>
      <AwardWrapper className="col-xs-12">
        <div className="row">
          <div className="col-xs-12">
            <AwardHeader>
              <AwardName>{awardName}</AwardName>
            </AwardHeader>
          </div>
        </div>

        {awardeeList.length > 0 &&
          awardeeList.map((awardee, index) => (
            <AwardeeMemo awardee={awardee} key={`${awardee._id}_${index}`} />
          ))}
      </AwardWrapper>
    </React.Fragment>
  );
}

AwardCard.propTypes = {
  awardName: PropTypes.string,
  awardeeList: PropTypes.array,
};

Awardee.propTypes = {
  awardee: PropTypes.object,
};

export default React.memo(AwardCard);
