import styled, { keyframes } from "styled-components";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import React, { useState, useCallback, useRef } from "react";
import parse from "html-react-parser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faBars,
  faCaretDown,
  faChevronDown,
  faClose,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { darkBlue, orange, standardH3, standardP } from "../styles";
import debounce from "lodash.debounce";
import Modal from "react-modal";

const menuItemTransition = keyframes`
    0% {
      background-color: white;
      color: ${standardH3};
    }
    100% {
      background-color: ${orange};
      color: white;
    }
`;
const menuPanelTransition = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const StyledNav = styled.nav`
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  position: sticky;
  background: white;
  z-index: 1;
  top: 0;
`;
const Container = styled.div`
  padding: 5px;
  max-width: 1170px;
  display: none;
  flex-direction: row;
  justify-content: center;
  @media (min-width: 992px) {
    display: flex;
  }
`;
const Item = styled.li`
  display: inline-block;
  padding: 0px;
`;
const ItemButton = styled.button`
  padding: 8px 15px;
  border: 0px solid transparent;
  font-size: 14px;
  font-weight: bold;
  color: ${({ hovered }) => (hovered ? "white" : standardH3)};
  background-color: ${({ hovered }) => (hovered ? orange : "transparent")};
  animation-name: ${({ hovered }) => (hovered ? menuItemTransition : "none")};
  animation-duration: 0.5s;
  display: inline-block;
  white-space: nowrap;
`;
const PanelWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
  padding: 0;
  margin: 0;
  border: 0px solid transparent;
`;
const Panel = styled.div`
  position: absolute;
  z-index: 100;
  margin: 0 auto;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1120px;
  opacity: ${({ displayPanel }) => (displayPanel ? "1" : "0")};
  animation-name: ${({ displayPanel }) =>
    displayPanel ? menuPanelTransition : "none"};
  animation-duration: 0.3s;
`;
const InnerPanel = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: row;

  &.awards-funding div {
    &:first-child,
    &:last-child {
      flex: 0 0 17%;
    }
  }
`;
const PanelSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  margin-left: ${({ first }) => (first ? "0" : "32px")};
`;
const SectionHeader = styled.h4`
  font-size: 18px;
  font-weight: 400;
  color: ${standardH3};
  margin-bottom: 15px;
  margin-top: 23px;
  text-align: left;
  @media (min-width: 992px) {
    margin-top: ${({ first }) => (first ? "0" : "23px")};
  }
`;
const SectionImage = styled.img`
  margin-bottom: 15px;
`;
const SectionImageLink = styled.a`
  margin: 0;
`;
const SectionText = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  text-align: left;
`;
const SectionButton = styled.a`
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: ${({ actionButton }) => (actionButton ? orange : darkBlue)};
  border: 1px solid ${({ actionButton }) => (actionButton ? orange : darkBlue)};
  border-radius: 5px;
  color: white;
  font-size: 13px;
  text-decoration: none;
  padding: 6px 16px;
  line-height: 1.38;
  cursor: pointer;
  align-self: flex-start;
`;
const SectionLink = styled.a`
  border: 0 none;
  border-bottom: 1px dashed #d3d3d3;
  margin: 0;
  padding: 10px 0 10px 20px;
  position: relative;
  width: 100%;
  clear: both;

  color: #707070;
  font-size: 12px;
  text-align: left;
  text-decoration: none;

  -webkit-transition: padding 0.2s linear;
  -o-transition: padding 0.2s linear;
  transition: padding 0.2s linear;

  &:hover {
    color: #111;
    padding-left: 25px;
  }
`;
const ArrowsWrapper = styled.span`
  position: absolute;
  left: 0;
  margin: auto 0;
`;
const SectionArticle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  a {
    text-decoration: none;
    &:hover p {
      color: #111;
    }
  }
`;
const SectionArticleImage = styled.img`
  max-width: 80px;
  width: 80px;
  height: 55px;
`;
const SectionArticleRight = styled.div`
  flex: 1;
  text-align: left;
  padding-left: 10px;
`;
const SectionArticleHeader = styled.p`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  color: #707070;
  margin-bottom: 0px;
`;
const SectionArticleParagraph = styled.p`
  font-size: 12px;
  color: ${standardP};
  margin-bottom: 0px;
`;
const MobileContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (min-width: 992px) {
    display: none;
  }
`;
const ImageA = styled.a`
  display: flex;
  justify-self: flex-start;

  @media (min-width: 768px) {
    display: none;
  }
`;
const HamburgerButton = styled.button`
  background-color: transparent;
  border: 0px solid transparent;
  padding: 20px;

  & svg {
    height: 1.8em;
    color: #707070;
  }
`;

const CloseButtonFixed = styled.button`
  border: 0px solid transparent;
  position: relative;
  align-self: flex-end;
  padding: 18px 25px;
  background-color: white;
`;
const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0 12px;
`;
const MobileMenuItem = styled.button`
  background-color: ${({ active }) => (active ? orange : "white")};
  border: 1px solid ${({ active }) => (active ? orange : "white")};
  box-shadow: ${({ active }) =>
    active ? "3px 3px 3px 3px rgba(0,0,0,0.15);" : "0"};
  padding: 15px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ active }) => (active ? "white" : standardH3)};
  font-size: 13px;
  font-weight: 600;
`;
const MobileMenuSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 8px;
`;

const menuItems = [
  {
    display: "About SOT",
    sections: [
      [
        {
          id: 1,
          type: "header",
          text: "What Is Toxicology?",
        },
        {
          id: 2,
          type: "image",
          href: "https://www.toxicology.org/about/relevance.asp",
          url: "/images/nav/about_global-nav.png",
          text: "Global research image",
        },
        {
          id: 3,
          type: "paragraph",
          text: "The study of the adverse effects of chemical, physical, or biological agents on living organisms and the ecosystem, including the prevention and amelioration of the effects.",
        },
        {
          id: 4,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/about/relevance.asp",
        },
      ],
      [
        {
          id: 5,
          type: "header",
          text: "About the Society",
        },
        {
          id: 6,
          type: "link",
          text: "Mission & Principles",
          url: "https://www.toxicology.org/about/sa/mission.asp",
        },
        {
          id: 7,
          type: "link",
          text: "Diversity, Equity, Inclusion and Accessibility",
          url: "https://www.toxicology.org/about/dei/diversity-equity-inclusion.asp",
        },
        {
          id: 8,
          type: "link",
          text: "Collaborative Impact",
          url: "https://www.toxicology.org/about/ci/collabImpact.asp",
        },
        {
          id: 9,
          type: "link",
          text: "Global Presence",
          url: "https://www.toxicology.org/about/gp/global.asp",
        },
        {
          id: 10,
          type: "link",
          text: "Global Partners",
          url: "https://www.toxicology.org/about/sa/globalpartners.asp",
        },
        {
          id: 11,
          type: "link",
          text: "Publications & Historical Documents",
          url: "https://www.toxicology.org/about/history/historical-documents.asp",
        },
      ],
      [
        {
          id: 12,
          type: "header",
          text: "Guiding Policies",
        },
        {
          id: 13,
          type: "link",
          text: "Code of Conduct",
          url: "https://www.toxicology.org/about/vp/code-of-conduct.asp",
        },
        {
          id: 14,
          type: "link",
          text: "Code of Ethics & Conflict of Interest",
          url: "https://www.toxicology.org/about/vp/code-of-ethics.asp",
        },
        {
          id: 15,
          type: "link",
          text: "Constitution and Bylaws",
          url: "https://www.toxicology.org/about/vp/bylaws.asp",
        },
        {
          id: 16,
          type: "link",
          text: "Financial Policy",
          url: "https://www.toxicology.org/about/fp/financialPolicy.asp",
        },
        {
          id: 17,
          type: "link",
          text: "Procedure and Policy Documents",
          url: "https://www.toxicology.org/about/vp/policies.asp",
        },
        {
          id: 18,
          type: "link",
          text: "Strategic Plan",
          url: "https://www.toxicology.org/about/vp/strategicPlan.asp",
        },
        {
          id: 19,
          type: "link",
          text: "Privacy Policy and Disclaimers",
          url: "https://www.toxicology.org/privacy.asp",
        },
      ],
      [
        {
          id: 20,
          type: "header",
          text: "Leadership & Notable Members",
        },
        {
          id: 21,
          type: "link",
          text: "Council",
          url: "https://www.toxicology.org/about/lnm/leadership.asp",
        },
        {
          id: 22,
          type: "link",
          text: "Elected and Appointed Committees",
          url: "https://www.toxicology.org/about/lnm/eac.asp",
          gated: true,
        },
        {
          id: 23,
          type: "link",
          text: "Past Presidents",
          url: "https://www.toxicology.org/about/lnm/pastPresidents.asp",
        },
        {
          id: 230,
          type: "link",
          text: "Past Leaders",
          url: "https://www.toxicology.org/about/history/historical-documents.asp",
        },
        {
          id: 24,
          type: "link",
          text: "25-Plus-Year Members",
          url: "https://www.toxicology.org/about/lnm/25yrMembers.asp",
          gated: true,
        },
        {
          id: 25,
          type: "link",
          text: "Charter Members",
          url: "https://www.toxicology.org/about/lnm/charter.asp",
          gated: true,
        },
        {
          id: 26,
          type: "link",
          text: "Emeritus Members",
          url: "https://www.toxicology.org/about/lnm/emeritus.asp",
          gated: true,
        },
        {
          id: 27,
          type: "link",
          text: "Honorary Members",
          url: "https://www.toxicology.org/about/lnm/honorary-members.asp",
        },
        {
          id: 28,
          type: "link",
          text: "SOT Award Recipients",
          url: "https://www.toxicology.org/awards/sot/recipients2023.asp",
        },
        {
          id: 30,
          type: "link",
          text: "In Memoriam",
          url: "https://www.toxicology.org/about/lnm/inMemoriam.asp",
        },
      ],
    ],
  },
  {
    display: "Members & Groups",
    sections: [
      [
        {
          id: 31,
          type: "header",
          text: "Current Members",
        },
        {
          id: 32,
          type: "link",
          text: "Manage Your Profile",
          url: "https://www.toxicology.org/memberProfile.asp",
          gated: true,
        },
        {
          id: 33,
          type: "link",
          text: "Access Member Directory",
          url: "https://toxchange.toxicology.org/network/members",
          gated: true,
        },
        {
          id: 34,
          type: "link",
          text: "Join a Component Group",
          url: "https://www.toxicology.org/groups/membership/joinGroup.asp",
        },
        {
          id: 35,
          type: "link",
          text: "Renew Your Dues",
          url: "https://www.toxicology.org/dues.asp",
          gated: true,
        },
        {
          id: 36,
          type: "link",
          text: "Upgrade Membership",
          url: "https://www.toxicology.org/groups/membership/joinSOT.asp",
          gated: false,
        },
        {
          id: 37,
          type: "link",
          text: "Access ToXchange",
          url: "https://toxchange.toxicology.org/home",
        },
      ],
      [
        {
          id: 38,
          type: "header",
          text: "Prospective Members",
        },
        {
          id: 39,
          type: "link",
          text: "Membership Application",
          url: "https://www.toxicology.org/groups/membership/joinSOT.asp",
        },
        {
          id: 40,
          type: "link",
          text: "Member Benefits",
          url: "https://www.toxicology.org/groups/membership/member-benefits.asp",
        },
        {
          id: 41,
          type: "link",
          text: "Member Types and Dues",
          url: "https://www.toxicology.org/groups/membership/types.asp",
        },
        {
          id: 42,
          type: "header",
          text: "SOT Merchandise",
        },
        {
          id: 43,
          type: "link",
          text: "SOT Online Store",
          url: "https://www.zazzle.com/store/sotonlinestore",
        },
      ],
      [
        {
          id: 44,
          type: "header",
          text: "Communities & Groups",
        },
        {
          id: 45,
          type: "link",
          text: "Postdocs",
          url: "https://www.toxicology.org/groups/postdoc/postdoc.asp",
        },
        {
          id: 46,
          type: "link",
          text: "Graduate Students",
          url: "https://www.toxicology.org/groups/gs/graduates.asp",
        },
        {
          id: 47,
          type: "link",
          text: "Regional Chapters",
          url: "https://www.toxicology.org/groups/rc/rc.asp",
        },
        {
          id: 48,
          type: "link",
          text: "Special Interest Groups",
          url: "https://www.toxicology.org/groups/sig/sig.asp",
        },
        {
          id: 49,
          type: "link",
          text: "Specialty Sections",
          url: "https://www.toxicology.org/groups/ss/ss.asp",
        },
        {
          id: 50,
          type: "link",
          text: "Undergraduate Students",
          url: "https://www.toxicology.org/groups/ug/affiliates.asp",
        },
        {
          id: 51,
          type: "link",
          text: "Undergraduate Educators",
          url: "https://www.toxicology.org/education/edu/educators.asp",
        },
      ],
    ],
  },
  {
    display: "Meetings & Events",
    sections: [
      [
        {
          id: 52,
          type: "header",
          text: "Upcoming Events:",
        },
        {
          id: 53,
          type: "article",
          url: "https://www.toxicology.org/events/calendar/component-groups-events.asp",
          target: "_self",
          imageUrl: "/images/nav/webinar-evt-mtg-tab-nav.png",
          imageText: "SOT webinar Logo",
          headerText: "SOT-Hosted Events",
          paragraphText: "Dates and Times Vary",
        },
        {
          id: 541,
          type: "article",
          url: "https://www.toxicology.org/events/shm/fda/FDAColloquia-2024-Dec3.asp",
          target: "_blank",
          imageUrl: "/images/nav/FDA-Evt-tab-new-lrg-min.jpg",
          imageText: "SOT FDA Colloquia: Challenges in Food and Ingredient Safety Logo",
          headerText: "SOT FDA Colloquia: Challenges in Food and Ingredient Safety",
          paragraphText: "December 3, 2024 • 9 AM–12:45 PM EDT",
        },
        {
          id: 552,
          type: "article",
          url: "https://www.toxicology.org/2025",
          target: "_blank",
          imageUrl: "/images/nav/AM25-Evt-tab.jpg",
          imageText: "2025 SOT Annual Meeting and ToxExpo Logo",
          headerText: "2025 SOT Annual Meeting and ToxExpo",
          paragraphText: "March 16–20, 2025",
        },
      ],
      [
        {
          id: 55,
          type: "header",
          text: "Event Calendar",
        },
        {
          id: 56,
          type: "paragraph",
          text: "Details on upcoming SOT events, as well as those submitted by other scientific meeting and event organizers.",
        },
        {
          id: 57,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/events/calendar/events.asp",
        },
      ],
      [
        {
          id: 58,
          type: "header",
          text: "SOT Meetings & Events",
        },
        {
          id: 59,
          type: "link",
          url: "https://www.toxicology.org/2024",
          text: "2024 Annual Meeting & ToxExpo",
        },
        {
          id: 60,
          type: "link",
          url: "https://www.toxicology.org/2025",
          text: "2025 Annual Meeting & ToxExpo",
        },
        {
          id: 61,
          type: "link",
          url: "https://www.toxicology.org/events/shm/cct/meetings.asp",
          text: "CCT Meetings",
        },
        {
          id: 62,
          type: "link",
          url: "https://www.toxicology.org/events/shm/fda/fda.asp",
          text: "SOT FDA Colloquia",
        },
        {
          id: 63,
          type: "link",
          url: "https://www.toxicology.org/about/history/annualMeeting.asp",
          text: "Upcoming and Past Annual Meetings",
        },
        {
          id: 64,
          type: "link",
          url: "https://www.toxicology.org/events/ssm/sponsored.asp",
          text: "Requesting SOT Sponsorship",
        },
      ],
    ],
  },
  {
    display: "Publications & News",
    sections: [
      [
        {
          id: 1065,
          type: "header",
          text: "<em>Toxicological Sciences</em>",
        },
        {
          id: 1066,
          type: "image",
          url: "https://www.toxicology.org/images/_nav-images/ToxSci-566x375-nav.png",
          text: "ToxSci logo",
        },
        {
          id: 1067,
          type: "paragraph",
          text: "The SOT official journal is one of the Society’s core programs, publishing a broad spectrum of impactful research in toxicology.",
        },
        {
          id: 1068,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/pubs/journal/toxsci",
        },
      ],
      [
        {
          id: 65,
          type: "header",
          text: "SOT Communications",
        },
        {
          id: 66,
          type: "link",
          url: "https://www.toxicology.org/pubs/comm/member-app.asp",
          text: "SOT Member App",
        },
        {
          id: 67,
          type: "link",
          url: "https://toxchange.toxicology.org/blogs/communique-blog",
          text: "<em>Communiqué</em> Blog",
        },
        {
          id: 68,
          type: "link",
          url: "https://www.adversereactionspodcast.com",
          text: "<em>Adverse Reactions</em> Podcast",
        },
        {
          id: 69,
          type: "link",
          url: "https://vimeo.com/showcase/sottv",
          text: "SOT TV",
        },
        {
          id: 70,
          type: "link",
          url: "https://www.toxicology.org/about/history/historical-documents.asp",
          text: "Publications & Historical Documents",
        },
        {
          id: 71,
          type: "link",
          url: "https://www.toxicology.org/pubs/statements/statements.asp",
          text: "SOT Statements",
        },
        {
          id: 72,
          type: "link",
          url: "https://www.toxicology.org/pubs/ad/advertising.asp",
          text: "Advertising Opportunities",
        },
      ],
      [
        {
          id: 73,
          type: "header",
          text: "SOT on Social Media",
        },
        {
          id: 74,
          type: "link",
          url: "https://www.facebook.com/societyoftoxicology/",
          text: "Facebook",
          target: "_blank",
        },
        {
          id: 75,
          type: "link",
          url: "https://www.instagram.com/sotoxicology/",
          text: "Instagram",
          target: "_blank",
        },
        {
          id: 76,
          type: "link",
          url: "https://www.linkedin.com/company/society-of-toxicology-sot-/",
          text: "LinkedIn",
          target: "_blank",
        },
        {
          id: 77,
          type: "link",
          url: "https://twitter.com/sotoxicology",
          text: "X (formerly Twitter)",
          target: "_blank",
        },
        {
          id: 78,
          type: "link",
          url: "https://www.youtube.com/channel/UCzyLrV6LQyO41m1E9NA8qCw",
          text: "YouTube",
          target: "_blank",
        },
        {
          id: 79,
          type: "link",
          url: "https://www.instagram.com/toxspotlight/",
          text: "<em>ToxSci</em> on Instagram",
          target: "_blank",
        },
        {
          id: 810,
          type: "link",
          url: "https://www.linkedin.com/company/toxicological-sciences/",
          text: "<em>ToxSci</em> on LinkedIn",
          target: "_blank",
        },
        {
          id: 80,
          type: "link",
          url: "https://twitter.com/ToxSci",
          text: "<em>ToxSci</em> on X (formerly Twitter)",
          target: "_blank",
        },
      ],
      [
        {
          id: 81,
          type: "header",
          text: "Toxicology & You",
        },

        {
          id: 82,
          type: "link",
          url: "https://www.toxicology.org/videos/search.asp",
          text: "SOT Video Library",
        },
        {
          id: 83,
          type: "link",
          url: "https://www.toxicology.org/pubs/newsroom/science-news.asp",
          text: "Science News from Government Agencies",
        },
        {
          id: 84,
          type: "link",
          url: "https://www.toxicology.org/pubs/newsroom/relevantNews.asp",
          text: "Is That Science News Relevant to Me?",
        },
        {
          id: 85,
          type: "link",
          url: "https://www.toxicology.org/pubs/nsp/nonSOTpubs.asp",
          text: "Member Publications & Discounts",
        },
        {
          id: 86,
          type: "link",
          url: "https://www.toxicology.org/videos/search.asp?siteSearch=*&amp;tags=toxicologists_on_toxicology",
          text: "Toxicologists on Toxicology",
        },
      ],
    ],
  },
  {
    display: "Awards & Fundings",
    class: "awards-funding",
    sections: [
      [
        {
          id: 87,
          type: "header",
          text: "Find an Award",
        },
        {
          id: 88,
          type: "link",
          url: "/award",
          text: "Search for an Award",
        },
        {
          id: 91,
          type: "header",
          text: "Award FAQs",
        },
        {
          id: 92,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/awards-faqs.asp",
          text: "Awards Review Process",
        },
        {
          id: 93,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/student-award-faqs.asp",
          text: "Student & Postdoc Awards",
        },
      ],
      [
        {
          id: 94,
          type: "header",
          text: "Award Programs",
        },
        {
          id: 95,
          type: "link",
          url: "https://www.toxicology.org/awards/scp.asp",
          text: "Science Communication Programs",
        },
        {
          id: 96,
          type: "link",
          url: "https://www.toxicology.org/awards/gf/gssep.asp",
          text: "Global Senior Scholar Exchange Program (GSSEP)",
        },
        {
          id: 97,
          type: "link",
          url: "https://www.toxicology.org/education/st/gift.asp",
          text: "Graduate Intern Fellowship in Toxicology (GIFT)",
        },
        {
          id: 98,
          type: "link",
          url: "https://www.toxicology.org/education/st/next.asp",
          text: "New Experiences in Toxicology Program (NEXT)",
        },
        {
          id: 99,
          type: "link",
          url: "https://www.toxicology.org/education/st/step.asp",
          text: "Supplemental Training for Education Program (STEP)",
        },
      ],
      [
        {
          id: 100,
          type: "header",
          text: "Recognizing Awardees",
        },
        {
          id: 101,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/recipients2024.asp",
          text: "2024 SOT Award Recipients",
        },
        {
          id: 102,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/historical.asp",
          text: "SOT Award Recipients Archive",
        },
        {
          id: 103,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eawards/recipients2023.asp",
          text: "2023 Endowment Fund Award Recipients",
        },
        {
          id: 104,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eawards/recipientsFull.asp",
          text: "Endowment Fund Award Recipients Archive",
        },
        {
          id: 1040,
          type: "link",
          url: "https://www.toxicology.org/about/lnm/accomplished.asp",
          text: "Prestigious Science Award Recipients",
        }, 
      ],
      [
        {
          id: 105,
          type: "header",
          text: "Research Funding Blog",
        },
        {
          id: 106,
          type: "image",
          url: "/images/nav/ResearchFund-nav.png",
          text: "Research Funding Blog Logo",
          href: "https://toxchange.toxicology.org/blogs/research-funding-blog",
          target: "_blank",
        },
        {
          id: 107,
          type: "paragraph",
          text: "Exclusive to SOT members, this blog contains information on grant and funding opportunities of relevance to toxicologists.",
        },
        {
          id: 108,
          type: "button",
          text: "Read More",
          url: "https://toxchange.toxicology.org/blogs/research-funding-blog",
          target: "_blank",
        },
      ],
    ],
  },
  {
    display: "Careers",
    sections: [
      [
        {
          id: 109,
          type: "header",
          text: "SOT Resources",
        },
        {
          id: 110,
          type: "link",
          url: "https://www.toxicology.org/jobbank",
          text: "Job Bank",
          target: "_blank",
        },
        {
          id: 111,
          type: "link",
          url: "https://www.toxicology.org/mentormatch",
          text: "Mentor Match",
          target: "_blank",
          gated: true,
        },
        {
          id: 112,
          type: "link",
          url: "https://www.toxicology.org/careers/if/internships.asp",
          text: "Internship Info",
        },
      ],
      [
        {
          id: 113,
          type: "header",
          text: "About Toxicology Careers",
        },
        {
          id: 114,
          type: "link",
          url: "https://www.toxicology.org/careers/toxicologist/becomeTox.asp",
          text: "Becoming a Toxicologist",
        },
        {
          id: 115,
          type: "link",
          url: "https://www.toxicology.org/groups/ss/ART/index.asp",
          text: "Careers in Regulatory Toxicology",
          target: "_blank",
        },
        {
          id: 116,
          type: "link",
          url: "https://www.toxicology.org/careers/tss/toxSalaries.asp",
          text: "Toxicology Salary Surveys",
        },
      ],
      [
        {
          id: 117,
          type: "header",
          text: "Career Development Resources",
        },
        {
          id: 118,
          type: "link",
          url: "https://www.toxicology.org/careers/cdr/ec-sessions.asp",
          text: "Education-Career Development Sessions",
        },
        {
          id: 119,
          type: "link",
          url: "https://www.toxicology.org/careers/cdr/cd-webinars.asp",
          text: "Career Development Webinars",
        },
        {
          id: 120,
          type: "link",
          url: "https://www.toxicology.org/education/ce/onlineCourses.asp",
          text: "CEd-Tox: Online Continuing Education",
        },
      ],
    ],
  },
  {
    display: "Education",
    sections: [
      [
        {
          id: 121,
          type: "header",
          text: "CEd-Tox: Continuing Education",
        },
        {
          id: 122,
          type: "image",
          url: "/images/nav/CEd-Tox-566x375-nav.png",
          text: "CED-Tox",
          href: "https://www.toxicology.org/education/ce/onlineCourses.asp",
        },
        {
          id: 123,
          type: "paragraph",
          text: "This online program provides 24/7 access to courses covering established knowledge and new developments in toxicology and related disciplines.",
        },
        {
          id: 124,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/education/ce/onlineCourses.asp",
        },
      ],
      [
        {
          id: 125,
          type: "header",
          text: "Recorded Presentations",
        },
        {
          id: 126,
          type: "link",
          url: "https://www.toxicology.org/videos/search.asp?siteSearch=*&amp;tags=plenary_sessions_and_lectures",
          text: "Plenary Lectures",
        },
        {
          id: 127,
          type: "link",
          url: "https://www.toxicology.org/careers/cdr/cd-webinars.asp",
          text: "Career Development Webinars",
        },
        {
          id: 128,
          type: "link",
          url: "https://www.toxicology.org/careers/cdr/ec-sessions.asp",
          text: "Education-Career Development Sessions",
        },
        {
          id: 129,
          type: "link",
          url: "https://www.toxicology.org/education/edu/eminent.asp",
          text: "Eminent Toxicologist Lectures",
        },
        {
          id: 130,
          type: "link",
          url: "https://www.toxicology.org/education/pw/ivLectures.asp",
          text: "<em>In Vitro</em> Toxicology Lectures",
        },
        {
          id: 131,
          type: "link",
          url: "https://www.toxicology.org/education/pw/webinars.asp",
          text: "Webinars",
        },
      ],
      [
        {
          id: 132,
          type: "header",
          text: "Resources for Educators",
        },
        {
          id: 133,
          type: "link",
          url: "https://www.toxicology.org/education/edu/educators.asp",
          text: "Undergraduate Educator",
        },
        {
          id: 134,
          type: "link",
          url: "https://www.toxicology.org/education/edu/educators.asp#IntroToxSlideSet",
          text: "Introduction to Toxicology Slides",
        },
        {
          id: 135,
          type: "link",
          url: "https://www.toxicology.org/awards/gf/toxscholar.asp",
          text: "ToxScholar Outreach",
        },
        {
          id: 136,
          type: "link",
          url: "https://www.toxicology.org/education/k12/k12.asp",
          text: "K–12 Outreach Materials",
        },
        {
          id: 137,
          type: "header",
          text: "Internships",
        },
        {
          id: 138,
          type: "link",
          url: "https://www.toxicology.org/careers/if/internships.asp",
          text: "Resources for Students",
        },
        {
          id: 139,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/awards.aspx?AwardID=205",
          text: "Support for Hosts",
        },
      ],
      [
        {
          id: 140,
          type: "header",
          text: "SOT Video Library",
        },
        {
          id: 141,
          type: "image",
          url: "/images/nav/sot-video-library_large.png",
          text: "SOT Video Library",
          href: "https://www.toxicology.org/videos/search.asp",
        },
        {
          id: 142,
          type: "paragraph",
          text: "View the Society’s collection of presentation recordings, interviews, and more.",
        },
        {
          id: 143,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/videos/search.asp",
        },
      ],
    ],
  },
  {
    display: "Endowment Fund",
    sections: [
      [
        {
          id: 144,
          type: "header",
          text: "Contribute Today",
        },
        {
          id: 145,
          type: "image",
          url: "/images/nav/Endowment-logo566x375-nav.png",
          text: "Endowment Fund logo",
          href: "https://www.toxicology.org/endowment/contribute/EndowmentOnline.asp",
        },
        {
          id: 146,
          type: "paragraph",
          text: "The Endowment Fund supports the future of toxicology through a series of funds designed to aid toxicologists and SOT programs.",
        },
        {
          id: 147,
          type: "button",
          text: "Donate Now",
          url: "https://www.toxicology.org/endowment/contribute/EndowmentOnline.asp",
          actionButton: true,
        },
      ],
      [
        {
          id: 148,
          type: "header",
          text: "About the Endowment Fund",
        },
        {
          id: 149,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eabout/fund.asp",
          text: "About the Funds",
        },
        {
          id: 162,
          type: "link",
          url: "/endowment",
          text: "Search the Funds",
        },
        {
          id: 150,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eabout/governance.asp",
          text: "History and Governance",
        },
        {
          id: 151,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eabout/governance.asp#report",
          text: "Annual Reports",
        },
        {
          id: 152,
          type: "link",
          url: "https://www.toxicology.org/endowment/ifc/fundContributors.asp",
          text: "Funds and Contributors",
        },
        {
          id: 153,
          type: "link",
          url: "https://www.toxicology.org/endowment/hrc/honorroll.asp",
          text: "Honor Roll of Contributors",
        },
      ],
      [
        {
          id: 154,
          type: "header",
          text: "Endowment Fund Awards",
        },
        {
          id: 155,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eawards/recipients2023.asp",
          text: "2023 Award Recipients",
        },
        {
          id: 156,
          type: "link",
          url: "https://www.toxicology.org/endowment/Eawards/recipientsFull.asp",
          text: "Award Recipients Archive",
        },
        {
          id: 157,
          type: "link",
          url: "https://www.toxicology.org/awards/sot/awards.aspx?Filter=22",
          text: "Review and Apply for Awards",
        },
      ],
      [
        {
          id: 158,
          type: "header",
          text: "Ways to Donate",
        },
        {
          id: 159,
          type: "image",
          url: "/images/nav/Endowment-sliced-cover.jpg",
          text: "SOT Endowment Annual Report Cover",
          href: "https://www.toxicology.org/endowment/contribute/donate.asp",
        },
        {
          id: 160,
          type: "paragraph",
          text: "There are many ways to contribute to the Endowment Fund—from planned giving to donations in honor of someone else.",
        },
        {
          id: 161,
          type: "button",
          text: "Read More",
          url: "https://www.toxicology.org/endowment/contribute/donate.asp",
        },
      ],
    ],
  },
];

function Nav({ linkPrefix }) {
  const [itemHovered, setItemHovered] = useState(null);
  const [panelHovered, setPanelHovered] = useState(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [mobileMenuOpenIndex, setMobileMenuOpenIndex] = useState(null);

  const toggleMobileMenu = () => setMobileMenu(false);
  const toggleMobileMenuOpenIndex = (index) =>
    mobileMenuOpenIndex === index
      ? setMobileMenuOpenIndex(null)
      : setMobileMenuOpenIndex(index);

  const menuClearRef = useRef();
  const clearMenuItemHovered = useCallback(() => {
    menuClearRef.ref = debounce(() => {
      setItemHovered(null);
    }, 100);
    menuClearRef.ref();
  }, []);
  const setMenuItemHovered = (index) => {
    if (index === null) {
      clearMenuItemHovered();
    } else {
      setItemHovered(index);
      menuClearRef.ref && menuClearRef.ref.cancel();
    }
  };
  const clearPanelItemHovered = useCallback(
    debounce(() => {
      setPanelHovered(null);
    }, 100),
    []
  );
  const setPanelItemHovered = (index) => {
    if (index === null) {
      clearPanelItemHovered();
    } else {
      setPanelHovered(index);
    }
  };

  const renderMenuItems = (item, i) => {
    switch (item.type) {
      case "header":
        return (
          <SectionHeader key={item.id} first={i === 0}>
            {parse(item.text)}
          </SectionHeader>
        );
      case "image":
        if (item.href) {
          return (
            <SectionImageLink
              href={item.href}
              key={item.id}
              {...(item.target ? { target: item.target } : {})}
            >
              <SectionImage key={item.id} src={item.url} alt={item.text} />
            </SectionImageLink>
          );
        } else {
          return <SectionImage key={item.id} src={item.url} alt={item.text} />;
        }
      case "paragraph":
        return <SectionText key={item.id}>{parse(item.text)}</SectionText>;
      case "button":
        return (
          <SectionButton
            key={item.id}
            href={item.url}
            {...(item.target ? { target: item.target } : {})}
            actionButton={item.actionButton}
          >
            {item.text}
          </SectionButton>
        );
      case "link":
        return (
          <SectionLink
            key={item.id}
            href={item.url}
            {...(item.target ? { target: item.target } : {})}
          >
            <ArrowsWrapper>
              <FontAwesomeIcon icon={faAnglesRight} size="2xs" />
            </ArrowsWrapper>
            {parse(item.text)}
            &nbsp;&nbsp;
            {item.gated ? <FontAwesomeIcon icon={faLock} /> : null}
          </SectionLink>
        );
      case "article":
        return (
          <SectionArticle key={item.id}>
            <LinkWrapper articleUrl={item.url} target={item.target}>
              <SectionArticleImage src={item.imageUrl} alt={item.imageText} />
            </LinkWrapper>

            <SectionArticleRight>
              <LinkWrapper articleUrl={item.url} target={item.target}>
                <SectionArticleHeader>{item.headerText}</SectionArticleHeader>
              </LinkWrapper>
              <SectionArticleParagraph>
                {item.paragraphText}
              </SectionArticleParagraph>
            </SectionArticleRight>
          </SectionArticle>
        );
    }
  };

  const LinkWrapper = ({ articleUrl, target, children }) =>
    articleUrl ? (
      <a href={articleUrl} target={target}>
        {children}
      </a>
    ) : (
      children
    );

  const panelToDisplay = itemHovered !== null ? itemHovered : panelHovered;

  return (
    <StyledNav>
      <Modal
        isOpen={mobileMenu}
        onRequestClose={toggleMobileMenu}
        contentLabel="Menu Modal"
        style={{
          content: {
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 100,
            display: "flex",
            flexDirection: "column",
            padding: 0,
          },
          overlay: {
            zIndex: 100,
          },
        }}
      >
        <CloseButtonFixed onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={faClose} className="" />
        </CloseButtonFixed>
        <MobileMenuWrapper>
          {menuItems.map((menuItem, index) => (
            <React.Fragment key={index}>
              <MobileMenuItem
                onClick={() => toggleMobileMenuOpenIndex(index)}
                active={index === mobileMenuOpenIndex}
              >
                <span>{menuItem.display}</span>
                <FontAwesomeIcon icon={faCaretDown} />
              </MobileMenuItem>
              {mobileMenuOpenIndex === index ? (
                <MobileMenuSection>
                  {(menuItem.sections || []).map((section) =>
                    (section || []).map(renderMenuItems)
                  )}
                </MobileMenuSection>
              ) : null}
            </React.Fragment>
          ))}
        </MobileMenuWrapper>
      </Modal>

      <MobileContainer className="align-items-center">
        <Col className="col-8 p-3">
          <ImageA href={`${linkPrefix}/`}>
            <img
              src="/images/SOT_logo_Tag_OL.png"
              alt="SOT Society of Toxicology logo"
            />
          </ImageA>
        </Col>
        <Col className="col-4">
          <HamburgerButton
            aria-label="Open Menu Button"
            onClick={() => setMobileMenu(true)}
          >
            <FontAwesomeIcon icon={faBars} />
          </HamburgerButton>
        </Col>
      </MobileContainer>
      <Container className="container">
        {menuItems.map((menuItem, index) => (
          <Item key={menuItem.display}>
            <ItemButton
              hovered={index === itemHovered || index === panelHovered}
              onMouseEnter={() => setMenuItemHovered(index)}
              onMouseLeave={() => setMenuItemHovered(null)}
            >
              {menuItem.display}
              &nbsp;
              <FontAwesomeIcon size="xs" icon={faChevronDown} className="" />
            </ItemButton>
          </Item>
        ))}
      </Container>
      <PanelWrapper>
        <Panel
          displayPanel={panelToDisplay !== null}
          onMouseEnter={() => setPanelItemHovered(panelToDisplay)}
          onMouseLeave={() => setPanelItemHovered(null)}
        >
          <InnerPanel
            className={
              menuItems[panelToDisplay] && menuItems[panelToDisplay].class
            }
          >
            {menuItems[panelToDisplay] &&
              menuItems[panelToDisplay].sections.map((section, index) => {
                return (
                  <PanelSection
                    key={section[0] && section[0].id}
                    first={index === 0}
                  >
                    {section.map(renderMenuItems)}
                  </PanelSection>
                );
              })}
          </InnerPanel>
        </Panel>
      </PanelWrapper>
    </StyledNav>
  );
}

Nav.propTypes = {
  linkPrefix: PropTypes.string,
};

export default Nav;
